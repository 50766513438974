<template>
	<div class="footerbar">
        
        <router-link to="/" class="footerbar__button" :class="{ 'active' : currentRoute === '/' }">
            <svg width="800px" height="800px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none">
                <path stroke="#ffffff" stroke-width="2" d="M4 5a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V5ZM14 5a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1V5ZM4 16a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3ZM14 13a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1v-6Z"/>
            </svg>
            Dashboard
        </router-link>

        <router-link to="/record" class="footerbar__button" :class="{ 'active' : currentRoute === '/record' }">
            <svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.9688 2C6.44875 2 1.96875 6.48 1.96875 12C1.96875 17.52 6.44875 22 11.9688 22C17.4888 22 21.9688 17.52 21.9688 12C21.9688 6.48 17.4988 2 11.9688 2ZM11.9987 16.23C9.65875 16.23 7.76875 14.34 7.76875 12C7.76875 9.66 9.65875 7.77 11.9987 7.77C14.3387 7.77 16.2287 9.66 16.2287 12C16.2287 14.34 14.3387 16.23 11.9987 16.23Z" fill="#ffffff"/>
            </svg>
            Record
        </router-link>

        <router-link to="/details" class="footerbar__button" :class="{ 'active' : currentRoute === '/details' }">
            <svg width="800px" height="800px" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                <g color="#000000" font-weight="400" font-family="sans-serif" white-space="normal" fill="gray">
                    <path d="M7.5 1A6.508 6.508 0 0 0 1 7.5C1 11.084 3.916 14 7.5 14S14 11.084 14 7.5 11.084 1 7.5 1zm0 1.04c3.022 0 5.46 2.438 5.46 5.46s-2.438 5.46-5.46 5.46A5.454 5.454 0 0 1 2.039 7.5c0-3.022 2.44-5.46 5.461-5.46z" style="line-height:normal;font-variant-ligatures:normal;font-variant-position:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-alternates:normal;font-feature-settings:normal;text-indent:0;text-align:start;text-decoration-line:none;text-decoration-style:solid;text-decoration-color:#000000;text-transform:none;text-orientation:mixed;shape-padding:0;isolation:auto;mix-blend-mode:normal" overflow="visible"/>
                    <path d="M11.853 11.146l-.707.708 3.5 3.5.707-.708z" style="line-height:normal;font-variant-ligatures:normal;font-variant-position:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-alternates:normal;font-feature-settings:normal;text-indent:0;text-align:start;text-decoration-line:none;text-decoration-style:solid;text-decoration-color:#000000;text-transform:none;text-orientation:mixed;shape-padding:0;isolation:auto;mix-blend-mode:normal" overflow="visible" fill-rule="evenodd"/>
                    <path d="M7 5v2H5v1h2v2h1V8h2V7H8V5z" style="line-height:normal;text-indent:0;text-align:start;text-decoration-line:none;text-decoration-style:solid;text-decoration-color:#000000;text-transform:none;isolation:auto;mix-blend-mode:normal;marker:none" overflow="visible"/>
                </g>
            </svg>
            Details
        </router-link>

        <router-link to="/history" class="footerbar__button" :class="{ 'active' : currentRoute === '/history' }">
            <svg width="800px" height="800px" viewBox="0 0 512 512" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="icon" fill="#ffffff" transform="translate(12.496777, 64.000000)">
                        <path d="M264.836556,1.42108547e-14 C370.875228,1.42108547e-14 456.836556,85.961328 456.836556,192 C456.836556,298.038672 370.875228,384 264.836556,384 C212.762984,384 165.531274,363.269523 130.944023,329.611165 L160.69855,299.03152 C187.599689,325.209881 224.335206,341.333333 264.836556,341.333333 C347.311079,341.333333 414.169889,274.474523 414.169889,192 C414.169889,109.525477 347.311079,42.6666667 264.836556,42.6666667 C182.362033,42.6666667 115.503223,109.525477 115.503223,192 C115.503223,196.042236 115.663829,200.046961 115.979008,204.008141 L158.169889,161.830111 L188.339779,192 L94.1698893,286.169889 L1.77635684e-14,192 L30.1698893,161.830111 L73.2641059,204.915722 C72.9805787,200.647132 72.836556,196.340385 72.836556,192 C72.836556,85.961328 158.797884,1.42108547e-14 264.836556,1.42108547e-14 Z M286.169889,106.666667 L286.169889,201.813333 L340.569889,238.293333 L317.103223,273.706667 L243.503223,224.853333 L243.503223,106.666667 L286.169889,106.666667 Z" id="Combined-Shape"></path>
                    </g>
                </g>
            </svg>
            History
        </router-link>

	</div>
</template>

<script>
import { mapGetters } from 'vuex';
// import router from '../router';

export default {
	name: 'MenuBar',

	data: () => ({
		currentRoute: ''
	}),

	computed: {
		...mapGetters({
			user: 'user'
		})
	},

	created() {
	},
	
	methods: {
		selectUser(){
		}
	}
}
</script>

<style lang="scss">
.footerbar {
    position: fixed;
    bottom: 5vw;
    left: 50%;
    transform: translateX(-50%);
    width: 84%;
    background: #2f2f2f;
    display: flex;
    justify-content: space-evenly;
    border-radius: 28px;
    height: 56px;
    padding: 0 15px !important;
    z-index: 10000;

    max-width: 504px;

    &__button {
        font-size: 10px;
        text-transform: uppercase;
        font-weight: bold;
        text-align: center;
        display: block;
        padding: 11px 8px 6px;
        box-sizing: border-box;
        width: 30%;
        opacity: .5;
        text-decoration: none;
        color: white;

        svg {
            width: 18px;
            height: auto;
            display: block;
            margin: 0 auto 5px;

            line,
            path {
                color: white !important;
                stroke: white !important;
            }
        }

        &.router-link-exact-active {
            color: white;
            opacity: 1;

            svg {
                line,
                path {
                    color: white !important;
                    stroke: white !important;
                }
            }
        }
    }
}
</style>